// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.
@import "../theme/variables";
/************ common ************/
@font-face {
  font-family: "ProximaRegular";
  src: url("../assets/fonts/ProximaNova-Regular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaBold";
  src: url("../assets/fonts/Proxima-Nova-Bold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaSemmiBold";
  src: url("../assets/fonts/Proxima-Nova-Sbold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaLight";
  src: url("../assets/fonts/Proxima-Nova-Alt-Light.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextMedium";
  src: url("../assets/fonts/AvenirNext-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextDemiBold";
  src: url("../assets/fonts/AvenirNext-DemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextBold";
  src: url("../assets/fonts/AvenirNext-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
html {
}
body {
  font-family: $font-main-regular;
  background: #E6E6E6;
  color: #424242;
  font-size: 15px;
  line-height: 18px;
}
ul {
  margin: 0 0 10px;
  padding: 0;
}
li {
  list-style: none;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: $primary;
}
a:hover {
  text-decoration: underline;
}
b{
  font-family: $font-main-semi-bold;
}
ion-col {
  padding: 0 5px;
  // text-align: center;
}
p {
  margin: 0 0 10px;
}
button{
  outline: none;
}
button[disabled]{
  opacity: 0.7;
}

input[disabled]{
  background: #E3E3E3;
  opacity: 0.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-main-bold;
}
//
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.font-semi-bold {
  font-family: $font-main-semi-bold !important;
}
.font-bold {
  font-family: $font-main-bold !important;
}
.font-regular{
  font-family: $font-main-regular;
}
.logo{
	text-align: center;
	margin: 5px 0 0;
	img{
		max-width: 100px;
	}
}
.error {
  color: red;
  text-align: left !important;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.pull-left,
.left {
  float: left;
}
.pull-right,
.right {
  float: right;
}
.grid {
  padding: 10px;
}
.text-pd{
  padding: 0 46px;
  line-height: 1.33;
}
.no-padding{
  padding: 0!important;
}
.pL5 {
  padding-left: 5px !important;
}
.pR5 {
  padding-left: 5px !important;
}
.pL15 {
  padding-left: 15px !important;
}
.pL20 {
  padding-left: 20px !important;
}
.pR15 {
  padding-right: 15px !important;
}
.pR85{
  padding-right: 85px!important;
}
.pR20{
  padding-right: 20px!important;
}
.pT0 {
  padding-top: 0px !important;
}
.pT20 {
  padding-top: 20px !important;
}
.pBT0 {
  padding-bottom: 0px !important;
}
.pBT10 {
  padding-bottom: 10px !important;
}
.pT60 {
  padding-top: 60px !important;
}
.pBT60 {
  padding-bottom: 60px !important;
}
.p10 {
  padding: 10px !important;
}
.p15 {
  padding: 15px !important;
}
.p20 {
  padding: 20px !important;
}
.mT10 {
  margin-top: 10px !important;
}
.mT20 {
  margin-top: 20px !important;
}
.mT30{
  margin-top: 30px !important;
}
.mT40 {
  margin-top: 40px !important;
}
.mT50 {
  margin-top: 50px !important;
}
.mL10 {
  margin-left: 10px !important;
}
.mL20 {
  margin-left: 20px !important;
}
.mL30{
  margin-left: 30px!important;
}
.mR0{
  margin-right: 0px!important;
}
.mR10{
  margin-right: 10px!important;
}
.mR20 {
  margin-right: 20px !important;
}
.mR30{
  margin-right: 30px!important;
}
.mBT0{
  margin-bottom: 0px!important;
}
.mBT5 {
  margin-bottom: 5px !important;
}
.mBT10 {
  margin-bottom: 10px !important;
}
.mBT20 {
  margin-bottom: 20px !important;
}
.mBT30 {
  margin-bottom: 30px !important;
}
.mBT40 {
  margin-bottom: 40px !important;
}
.mBT60 {
  margin-bottom: 60px !important;
}
.text-primary {
  color: $secondary !important;
}
.text-green {
  color: #4ab542 !important;
}
.text-blue {
  color: #2897f7 !important;
}
.text-grey {
  color: #acacac !important;
}
.text-gray {
  color: #424242 !important;
}
.text-red {
  color: #db3f2f !important;
}
.bg-grey {
  background: #f9f9f9;
}
.bg-color{
  background: #E3E3E3;
  border-radius: 6px;
}
.alert-danger {
  color: #ca001a;
}
.text-center {
  text-align: center;
}
.font-italic {
  font-style: italic;
}
.font-size11 {
  font-size: 11px !important;
}
.font-size12 {
  font-size: 12px !important;
}
.font-size14 {
  font-size: 14px !important;
}
.font-size15{
  font-size: 15px!important;
}
.font-size16{
  font-size: 16px!important;
}
.font-size18 {
  font-size: 18px !important;
}
.font-size20 {
  font-size: 20px !important;
}
.box {
  background: #fff;
  h2.title{
    font-size: 20px;
    font-family: $font-main-bold;
    color: #1f2724;
    border-bottom: 1px solid #e4e9e7;
    padding-bottom: 18px;
  }
  .box-title {
    border-bottom: 1px solid #f9f9f9;
    padding: 10px 15px;
    p {
      margin-bottom: 0;
      font-weight: 600;
    }
  }
  .box-content {
    padding: 20px;
    .box-intro {
      p {
        margin: 0;
        span {
          display: inline;
        }
      }
    }
  }
}
.box-shadow {
  box-shadow: 0 8px 20px 0 rgba(0, 118, 186, 0.16);
}
.box-border {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.form-group {
  margin-bottom: 20px;
  label {
    font-size: 15px;
    font-family: $font-main-semi-bold;
    margin-bottom: 10px;
    display: block;
  }
}
.form-reset {
  .center {
    text-align: center;
  }
  .box{
    .box-content{
      h2{
        font-size: 20px;
        font-family: $font-main-bold;
        color: #1f2724;
        border-bottom: 1px solid #e4e9e7;
        padding-bottom: 18px;
      }
      .create-pin{
        font-size: 20px;
        font-family: $font-main-bold;
        text-align: center;
        letter-spacing: 0.4px;
        color: #000000;
        padding-bottom: 18px;
      }
      .suggest-pin {
        font-size: 16px;
        text-align: center;
        font-family: $font-main-regular;
      }
    }
  }
}
.label-form {
  font-size: 15px;
  font-family: $font-main-semi-bold;
  margin-bottom: 10px;
  display: block;
  color: #424242;
}
.form-control {
  background: #fff;
  color: #424242;
  border: 1px solid #ecebed;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  font-family: $font-main-semi-bold;
  border-radius: 6px;
  height: 48px;
  padding: 0 20px;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
    -moz-appearance: none;
}

.form-control[type="number"]{
  -moz-appearance: textfield;
}
.form-control[type="number"]:hover,
.form-control[type="number"]:focus{
  -moz-appearance: textfield!important;
}

.form-control[type="password"]:focus,
.form-control[type="text"]:focus,
.form-control[type="number"]:focus,
.form-control[type="email"]:focus {
  outline: none;
  border: 1px solid transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: 0px 0px 0 2px $primary;
  -webkit-box-shadow: 0px 0px 0 2px $primary;
  -moz-box-shadow: 0px 0px 0 2px $primary;
}
.form-control.disabled{
  background: #E3E3E3!important;
  opacity: 0.5;
  border: none;
}
.form-control::-webkit-input-placeholder {
  line-height: normal;
}

.form-control:-ms-input-placeholder {
  line-height: normal;
}
.form-control:placeholder {
  line-height: normal;
}
.has-error .form-control {
  outline: none;
  border: 1px solid transparent;
  -webkit-appearance: none;
  box-shadow: 0px 0px 0 2px $error_color!important;
  -webkit-box-shadow: 0px 0px 0 2px $error_color!important;
  -moz-box-shadow: 0px 0px 0 2px $error_color!important;
}
.btn {
  color: #000;
  border: none;
  border-radius: 24px;
  font-size: 18px;
  padding: 5px 20px;
  font-family: $font-main-bold;
  outline: none;
}
.btn-none {
  width: 200px;
  height: 33px;
  font-size: 15px;
  letter-spacing: 0.33px;
}
.btn-opacity{
  opacity: 0.4;
  width: 280px;
  height: 48px;
}
.btn-nofull{
  width: 280px;
  height: 48px;
}
.btn-full{
  width: 100%;
  height: 50px;
}
.btn-percent{
  width: 80%;
  height: 50px;
}
.btn-border6 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-border10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.btn-border16 {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.btn-border18 {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}
.btn-trans-primary {
  font-family: $font-main-bold;
  background: transparent;
  border: 2px solid $primary;
  font-size: 18px;
  color: $color_main;
  padding: 8px 20px;
  font-weight: bold;
}
.btn-red {
  background: #d0021b;
  color: #fff;
}
.btn-green {
  background: #20d994;
  color: #fff;
}
.btn-blue {
  background: #3399cc;
  color: #fff;
}
.btn-grab {
  background: #138138;
  color: #fff;
}
.btn-main {
  background-image: $main_bg !important;
  color: $color_btn !important;
}

.btn-primary {
  background: $primary !important;
  color: #000 !important;
}

.btn-trans-main {
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, #0fada6, #397097 58%, #25457b);
  border-image-slice: 1;
  color: $color_main !important;
}
.border-radius6 {
  border-radius: 6px;
}
.border-radius24{
  border-radius: 24px;
}

/************ style: checkbox ********/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  font-family: $font-main-bold;
  color: $primary;
  font-size: 15px;
  display: block;
}

input[type="checkbox"] + label::before {
  background: url("../assets/images/chk_check.png") no-repeat 0 0;
  background-size: contain;
  display: inline-block;
  content: "";
  color: #b70925;
  width: 20px;
  height: 20px;
  margin: -2px 8px 0 0;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  float: left;
}

input[type="checkbox"]:checked + label:before {
  background: url("../assets/images/chk_check_o.png") no-repeat 0 0;
  background-size: contain;
}
//********check-profile checkbox****//
.check-profile input[type="checkbox"] {
  display:none;
}

.check-profile input[type="checkbox"] + label {
  font-weight: normal;
  display: block;
}

.check-profile input[type="checkbox"] + label:before {
  background: url("../assets/images/chk_check.png") no-repeat 0 0;
  background-size: contain;
  display: inline-block;
  content: "";
  color: #b70925;
  width: 20px;
  height: 20px;
  margin: -2px 8px 0 0;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  float: left;
}
.check-profile input[type="checkbox"] + label:after{
  background: none;
}
.check-profile input[type="checkbox"]:checked + label:before {
  background: url("../assets/images/chk_check_o.png") no-repeat 0 0;
  background-size: contain;

}
.check-profile input[type="checkbox"]:checked + label:after{
  background: none;
}
// custom radio
.left-radio.left-radio.left-radio{
  text-align: left;
  margin: 0 20px 30px;
}

.left-radio input[type="checkbox"] {
  display: none;
}

.left-radio input[type="checkbox"] + label {
  font-family: $font-main-bold;
  color: $primary;
  font-size: 15px;
  display: block;
}

.left-radio input[type="checkbox"] + label:before {
  background: url("../assets/images/rdo_check.png") no-repeat 0 0;
  background-size: contain;
  display: inline-block;
  content: "";
  color: #b70925;
  width: 20px;
  height: 20px;
  margin: -2px 8px 0 0;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  float: left;
}

.left-radio input[type="checkbox"]:checked + label:before {
  background: url("../assets/images/rdo_check_o.png") no-repeat 0 0;
  background-size: contain;
}

.left-radio input[type="checkbox"] + label:after{
  display: none;
}

.left-radio input[type="checkbox"]:checked + label:after{
  display: none;
}
/************ style: radio ********/
.left-check{
  display: flex;
}
.left-check input[type="radio"] {
  display: none;
}

.left-check input[type="radio"] + label {
  font-weight: normal;
  display: block;
  text-align: left;
}

.left-check input[type="radio"] + label:before {
  border: #f0eff0 1px solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
    display: inline-block;
    content: "";
    width: 14px;
    height: 14px;
    margin: -2px 8px 0 0;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;

}
.left-check input[type="radio"] + label:after {
  background: none;
}

.left-check input[type="radio"]:checked + label:before {
  background: #24437a;
  border: #f0eff0 1px solid;
  content: "";
}
.left-check input[type="radio"]:checked + label:after {
  background: none;
}

/************ style: checkbox ********/
input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  font-family: $font-main-bold;
  color: $primary;
  font-size: 15px;
  display: block;
}

input[type="radio"] + label:before {
  background: url("../assets/images/rdo_check.png") no-repeat 0 0;
  background-size: contain;
  display: inline-block;
  content: "";
  color: #b70925;
  width: 20px;
  height: 20px;
  margin: -2px 8px 0 0;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  float: left;
}

input[type="radio"]:checked + label:before {
  background: url("../assets/images/rdo_check_o.png") no-repeat 0 0;
  background-size: contain;
}

/***** ionic common ***/
.ion-page {
  background: #FFF;
  box-shadow: 0 8px 20px 0 rgba(0, 118, 186, 0.16);
  max-width: 480px;
  margin: 0 auto;
}
ion-header{
  box-shadow: 0 4px 10px 0 rgba(0, 72, 122, 0.16);
}
/***** Banner ****/
.block-banner {
  img {

  }
}

/***** Footer ****/
ion-footer {
  background: #fff;
  z-index: 100;
}
.footer-md:before {
  background-image: none;
}

app-coppyright{
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
}
.text-copyright {
  font-size: 12px;
  color: #cdcdcd;
  margin: 0;
  padding-bottom: 10px;
  display: inline-block;
  text-align: center;
  width: 100%;
}
ion-content{
  app-coppyright{
    position: relative;
    bottom: 0;
  }
}
app-home-church-life{
  app-coppyright{
    position: fixed;
    bottom: 72px;
  }
}

app-home{
  app-coppyright{
    position: fixed;
    bottom: 72px;
  }
}

.block-footer {
  min-height: 70px;
  background: $primary;
  box-shadow: 0 -1px 10px 0 rgba(0, 118, 186, 0.2);
  padding: 15px 30px 0 30px;
  .menu-footer{
    .col-footer-left{
      img{
        width: 25px;
      }
    }
    .col-footer-left{
      text-align: left;
      p{
        left: -13px;
      }
    }
    .col-footer-right{
      text-align: right;
      p{
        right: -8px;
      }
      img{
        width: 25px;
      }
    }
    .col-footer-center{
      text-align: center;
      p{

      }
      img{
        width: 25px;
      }
    }
  }
  .btn {
    border-radius: 16px;
    padding: 8px 20px;
    font-size: 15px;
    min-width: 130px;
  }
  .btn-footer {
    opacity: 0.7;
    .btn-footer-click{
      display: inline-block;
      cursor: pointer;
    }
    p {
      font-size: 10px;
      font-weight: 600;
      color: #ffffff;
      line-height: 1.8;
      letter-spacing: 0.33px;
      font-family: $font-main-semi-bold;
      position: relative;
    }
  }
  .btn-footer.active{
    opacity: 1;
  }
}

/******** style select ********/
.styled-select {
  width: 100%;
  // overflow: hidden;
  border-radius: 6px;
  height: 48px;
  line-height: 48px;
  overflow: -moz-hidden-unscrollable;
  background: #FFFFFF url("../assets/images/arrow_select.png") no-repeat;
  background-position: right 15px bottom 19px;
  background-size: 12px;
  border: 1px solid #ecebed;
  display: inline-block;
  position: relative;
  margin-bottom: 0;
}
.styled-select select::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background-color: red;
}

.styled-select select {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: 0;
  position: absolute;
  left: 0;
  top: 0;
  color: #424242;
  outline: none;
}

h2.page-title {
  font-size: 20px;
  color: #1f2724;
  border-bottom: 1px solid #e4e9e7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

/** form login **/
.form-login {
  padding: 0 20px 20px;
  margin-top: -80px;
  z-index: 100;
  position: relative;

  .box {
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(0, 118, 186, 0.16);
    .box-content {
      padding: 30px 10px 15px;
    }
  }
  p {
    text-align: center;
    font-size: 13px;
    color: #424242;
  }
  h2 {
    color: #000;
    font-size: 20px;
    font-family: $font-main-bold;
    text-align: center;
    margin: 0 0 20px;
  }
  .form-group {
    position: relative;
  }
  .form-control {
    background: #fff;
    color: #424242;
    box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
    font-family: $font-main-semi-bold;
    border-radius: 6px;
    height: 48px;
    padding: 0 20px;
    font-size: 15px;
  }
  .styled-select {
    height: 48px;
    background-color: #fff;
    select {
      background: transparent;
    }
  }
  .center {
    text-align: center;
  }
  .text-info {
    padding: 0 10px;
    p {
      font-size: 16px;
      font-family: $font-main-regular;
      color: #000;
    }
    .text-create {
      padding: 0 50px;
      text-align: justify;
      font-size: 11pt;
      a{
        display: inline-block;
        color: $primary;
      }
    }
  }
  a {
    display: block;
    color: #424242;
    font-family: $font-main-semi-bold;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  span {
    display: block;
    font-family: $font-main-semi-bold;
  }
  .link-resent {
    color: $color_main;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
  }
  .text-decoration {
    text-decoration: underline;
  }
  .validate-pin {
    position: absolute;
    top: 0;
    right: 0;
    img {
      max-height: 48px;
    }
  }
  .row-contact-otp{
    p{
      font-size: 15px;
      span{
        font-family: $font-main-regular;
        display: inline-block;
      }
      a{
        display: inline-block;
        color: $color_main;
      }
    }
  }
  .row-resent-otp{
    .countdown{
      float: left;
      border-radius: 6px;
      box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
      border: solid 1px #ecebed;
      background-color: #f5f5f5;
      font-size: 15px;
      padding: 5px 10px;
      img{
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 15px;
      }
      span{
        display: inline-block;
        font-family: $font-main-regular;
      }
    }
    .link-resent{
      float: right;
      padding-top: 8px;
    }
    .link-resent.disabled{
      color: #B4B5B4;
    }
  }
}

//**** form otp ****/
.form-otp{
  .form-control{
    padding: 0;
    text-align: center;
    font-size: 20px;
    font-family: $font-main-regular;
    width: 48px;
    height: 48px;
  }
}
//*** OTP success ***//
.block-otp-success {
  .text-primary {
    font-family: $font-main-regular;
    font-size: 15px;
    color: #000000;
    line-height: 1.33;
  }
}

//*** form profile ***//
.form-profile {
  .block-avatar {
    text-align: center;
    .image {
      margin-bottom: 0;
      img {
        max-width: 110px;
        border-radius: 50%;
        border: 2px solid #24437a;
      }
    }
    span {
      font-size: 12px;
    }
  }
  ::placeholder {
   color: #b4b5b4;
    opacity: 1;
  }
  input[disabled]{
    background: #E3E3E3;
    opacity: 0.5;
  }
  .opacity06{
    opacity: 0.6;
  }
  .block-suggest {
    margin-bottom: 10px;
    label {
      font-size: 12px;
      margin-bottom: 10px;
      display: block;
    }
    ul {
      li {
        margin-right: 10px;
        float: left;
        margin-bottom: 10px;
        a {
          color: #424242;
          display: block;
          font-size: 12px;
          border-radius: 6px;
          border: 1px solid #e4e9e7;
          background: #fff;
          padding: 2px 10px;
          text-decoration: none;
        }
        a:hover {
          border: 1px solid $primary;
          text-decoration: none;
        }
      }
    }
  }
}

//***** pdpa ***//
.content-pdpa{
  font-family: $font-main-regular;
  font-size: 14px;
  margin-bottom: 20px;
  p {
    margin-bottom: 20px;
    color: #4a4a4a;

  }
  a{
    text-decoration: underline;
  }
  a:hover{
    text-decoration: none;
  }
  .btn-opacity{
    opacity: 0.4;
  }
}
//***** home *****//
.qrcontent {
  display: inline-block;
  width: 100%;
  .listqr {
    margin: 15px 21px 0px;
    //display: inline-block;
    ul.qr2 {
      margin-bottom: 0;
      text-align: left;
      li {
        display: inline-block;
        margin: 0 0 10px;
        text-align: center;
        width: 33%;
        vertical-align: top;
        text-align: center;
        .select {
          position: relative;
          padding: 12px 0 11px;
          width: 52px;
          border-radius: 16px;
          display: inline-block;
          height: 51.7px;
          span{
            vertical-align: middle;
          }
          img {
            height: 25px;
          }
          .overlayqr {
            border-radius: 16px;
            background: #00487a;
            img {
              width: 26px;
              margin-top: 11px;
            }
          }
        }
        p {
          margin-top: 8px;
          text-align: center;
          font-size: 12px;
          font-family: $font-main-semi-bold;
          color: #424242;
          line-height: 1.67;
          letter-spacing: 0.34px;
          margin-bottom: 0;
        }
        .s1 {
          box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
          background: $main_bg;
        }
        .s2 {
          box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
          background: $main_bg;
        }
        .s3 {
          box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
          background: $main_bg;
        }
        .s4 {
          box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
          background: $main_bg;
        }
      }
    }
    ul.qr1 {
      li {
        float: left;
        margin-right: 15px;
        margin-bottom: 20px;
        position: relative;
        .itemqr {
          border-radius: 8px;
          width: 144px;
          padding: 15px 0 11px;
          box-shadow: 0 2px 10px 0 rgba(0, 72, 122, 0.2);
          .imageqr {
            text-align: center;
            img {
              height: 40px;
            }
          }
          p {
            margin-bottom: 0;
            text-align: center;
            color: $primary;
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .qrcodeimage {
    text-align: center;
    border-top: 1px solid #cfd8dc;
    margin-top: 10px;
    padding-top: 20px;
    .imgsqr {
      display: inline-block;
      padding: 15px;
      border: 3px solid $primary;
      border-radius: 16px;
      margin: 8px auto 16px;
      max-width: 180px;
      canvas{
        max-width: 100%;
      }
    }
  }
}
//*****Form Update Success****//
.form-update.form-update.form-update.form-update.form-update.form-update {
  position: relative;
  width: 100%;
  display: table;
  margin-top: 0;
  padding: 20px;
  height: 100%;
  .box{
    box-shadow: none;
    display: table-cell;
    vertical-align: middle;
    background: transparent;
    .box-content{
      box-shadow: 0 8px 20px 0 rgba(0, 118, 186, 0.16);
      padding: 50px 10px 50px;
      border-radius: 8px;
      h2{
        margin-top: 0;
      }
    }
  }

}
//*****Form Create Pin****//
.suggest-create {
  font-size: 16px;
  text-align: center;
  color: #000000;
  font-family: $font-main-regular;
  border-bottom: none;
}
.bold {
  font-family: $font-main-bold;
}
.pbt18{
  padding: 0 88px;
  padding-bottom: 18px;
}
.invalid-pin{
  line-height: 1.33;
  color: #db3f2f;
  padding-bottom: 24px;
}

//*****form profile details****//
.gender-details{
  height: 48px;
  border-radius: 6px;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  width: 100%;
  color: #424242;
  line-height: 1.33;
  font-size: 15px;
  font-family: $font-main-semi-bold;
  margin-bottom: 20px;
  border: 1px solid #e6e5e7;
  // box-shadow: 0px 0px 0 1px #ecebed;
  // -webkit-box-shadow: 0px 0px 0 1px #ecebed;
  // -moz-box-shadow: 0px 0px 0 1px #ecebed;
}
.gender-details.active{
  -webkit-appearance: none;
  box-shadow: 0px 0px 0 2px $primary;
  -webkit-box-shadow: 0px 0px 0 2px $primary;
  -moz-box-shadow: 0px 0px 0 2px $primary;
  border: 1px solid transparent;
}
//*****form profile family****//

.login-number-membership{
  img{
    width: 20px;
    vertical-align: top;
    margin-right: 10px;
    margin-top: -1px;
  }
  img.image-check{
    width: 100px;
  }
  img.img-minis{
    width: 40px;
  }
}
.panel-primary{
  .prefences{
    color: #24437a;
    margin-top: 8px;
    font-family: $font-main-bold;
  }
  .panel-body{
    p{
      font-size: 12px;
      color: #424242;
      line-height: 0.67;
      font-family: $font-main-regular;
    }
    .profile-pre{
      line-height: normal;
    }
  }
}
//*****Form profile****//
.style-select {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  height: 48px;
  overflow: -moz-hidden-unscrollable;
  background: #E3E3E3 url("../assets/images/arrow_select.png") no-repeat;
  background-position: right 15px bottom 20px;

  background-size: 12px;
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  opacity: 0.5;
}

.style-select select {
  background: transparent!important;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: 0;
  position: absolute;
  left: 0;
  top: 0;
  color: #424242;
}
.no-border{
  border: none;
}

/************ back button *************/
.btn-back-button{
  position: absolute;
  bottom: 20px;
  right: 20px;
  .back{
    background: $primary;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
    color: $color_btn;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding-top: 14px;
    text-align: center;
    border-radius: 50%;
    margin-right: 5px;
    float: right;
    i{
      font-size: 22px;
    }
  }
}
// *********Loading*******//
.block-loading-page{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  text-align: center;
  z-index: 999;
  left: 0;
  ion-spinner{
    transform: scale(3);
    position: absolute;
    top: 40%;
    width: 12px;
  }
}

/*** responsive ***/
@media (max-width: 480px){
  .ion-page{
    max-width: 100%;
  }
}
@media (max-width: 350px){
  .form-otp{
    .form-control{
      width: 100%;
      height: auto;
    }
  }
  .login-number-membership{

    img.img-minis{
      width: 100%;
    }
  }
}
@media (max-height: 695px){
  app-home{
    ion-content{
      app-coppyright{
        position: relative;
        bottom: 0;
      }
    }
  }

}
@media (max-height: 550px){
  app-home-church-life{
    ion-content{
      app-coppyright{
        position: relative;
        bottom: 0;
      }
    }
  }

}
@media (max-height: 590px){
  .btn-back-button{
    position: relative;
  }
}