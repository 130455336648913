// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

.popup {
    padding: 20px !important;
    --height: 690px !important;
}

.popup-small {
    padding: 20px !important;
    --height: 240px !important;
}

.popup .ion-page, .popup-small .ion-page {
    --border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
}

.popup .ion-page ion-content ion-text {
    padding: 20px !important;
}

.text-light {
    font-weight: lighter;
}

.text-bold {
    font-weight: bold;
}